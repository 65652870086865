:root {
  --primary-color: #ef6c98;
  --color-off-red: #faa7b7;
  --card-shadow: 11px 10px 38px hsla(0, 0%, 0%, 12%);
  --color-white: #fff;
  --global--color-border: #e2e2e2;
  --color-off-black: #888888;
  --color-light-blue: #b6ccf5;
}
@import url("https://fonts.googleapis.com/css2?family=Paprika&family=Poppins&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css");
body {
  overflow-x: hidden;
}
h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 800;
}
p {
  line-height: 1.5rem;
  margin-bottom: 20px;
}
.site-logo {
  width: 30%;
}
.dwk-header-top {
  top: 0;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}
.dwk-header-top.hide {
  transform: translateY(-100%);
}

.dwk-header-top.show {
  transform: translateY(0);
  position: fixed;
  background: #fff;
  box-shadow: 0px 0px 5px 0px #dfdfdf;
  width: 100%;
  margin: 0 auto;
  padding: 10px 5%;
}
.dwk-header-top.show .site-logo img {
  width: 35%;
}
.dwk-header-top.show .navbar-wrapper {
  margin-top: 10px;
}
#navbar-default ul li a.active,
#navbar-default ul li a:hover {
  border: 1px solid #000;
  border-radius: 30px;
  padding: 0.3rem 1.5rem;
}
#navbar-default ul li a {
  border: 1px solid transparent;
  padding: 0.3rem 1.5rem;
}
.text-dark_primary {
  --tw-text-opacity: 1;
  color: rgb(6 34 63 / var(--tw-text-opacity));
}
.border-white {
  border-color: var(--color-white) !important;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.bg-primaryLinear {
  background-image: linear-gradient(180deg, #b6ccf5 0.48%, #d5e3f1 100%);
}
.bg-bg_light_primary {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 253 / var(--tw-bg-opacity));
}
.btn {
  border-radius: 0.375rem 0.375rem 1.5rem;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(6 34 63 / var(--tw-border-opacity));
  padding: 0.5rem 2.25rem;
  font-weight: 500;
}
.btn-capsule {
  --tw-bg-opacity: 1;
  background-color: var(--color-off-red);
  color: var(--color-white);
  font-size: 14px;
  display: inline-flex;
  padding: 6px 24px;
  border-radius: 60px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* CSS */
.button-visitesite {
  margin: 0 0 10px 0;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: inline-block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(
    45deg,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  );
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-visitesite:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-visitesite:active {
  transform: scale(0.95);
}
.text-white .btn {
  border-color: var(--color-white);
}
.btn-animation .btn {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}
.btn-wrapper {
  gap: 20px;
  align-items: flex-end;
  display: inline-flex;
  justify-content: flex-end;
}

.btn-animation .btn:nth-child(2) {
  -webkit-animation: action2 1s infinite alternate;
  animation: action2 1s infinite alternate;
}
.btn-dark button.btn {
  background-color: rgb(6 34 63);
  color: var(--color-white);
}
@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
@-webkit-keyframes action2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(-30px);
  }
}

@keyframes action2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(-30px);
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
}
@media (min-width: 768px) {
  .md\:container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

h2 {
  font-family: "Paprika", cursive;
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
}

h3 {
  font-family: "Paprika", cursive;
  font-size: 3rem;
  line-height: 1;
}

h4 {
  font-family: "Paprika", cursive;
  font-size: 1.875rem;
  line-height: 2.25rem;
  line-height: 1.625 !important;
}
/* Progress Bar */
.dwk-progressbar {
  position: relative;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 5px 5px 0px 0px rgb(0 0 0 / 0.1),
    -10px 0px 30px 5px rgb(0 0 0 / 0.06);
}
@keyframes progress {
  0% {
    --percentage: 0;
  }
  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #369;
  --secondary: #adf;
  --size: 100px;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    var(--primary) calc(var(--percentage) * 1%),
    var(--secondary) 0
  );
  mask: radial-gradient(white 55%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 55%, #000 0);
  -webkit-mask-mode: alpha;
}

[role="progressbar"]::after {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: var(--primary);
}
.progress-content p {
  margin-bottom: 0;
}
/* Skill items */
.skills__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-animation: fadeIn 0.3s ease-out;
  animation: fadeIn 0.3s ease-out;
}
.skills__item {
  box-shadow: var(--card-shadow);
  border-radius: 0.63rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  margin: 0 0.5rem 1rem;
  cursor: help;
}
.skills__item__img {
  display: block;
  width: 3rem;
  margin: auto;
}
@media (min-width: 768px) {
  h4 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}
h5 {
  font-family: Paprika;
  font-weight: 700;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgb(37 61 87 / var(--tw-text-opacity));
  font-size: 1.2em;
}
h6 {
  font-family: Paprika;
  font-weight: 700;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgb(37 61 87 / var(--tw-text-opacity));
}
/* Testimonial */
.testimonialmainwrapper {
  vertical-align: middle;
  justify-content: space-evenly;
  display: inline-flex;
  align-content: stretch;
  align-items: center;
}
.testimonial-wrapper {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 100px 40px;
  background: #fff;
  text-align: center;
}
.testimonial-wrapper swiper-slide {
  padding: 0 80px;
}
.testimonial-wrapper .swiper-button-prev,
.testimonial-wrapper .swiper-button-next {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.testimonial-wrapper .swiper-button-next:after,
.testimonial-wrapper .swiper-button-prev:after {
  font-size: 20px;
  color: #969696;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
}
/* Isotope */
/* ---- .element-item ---- */

.element-item {
  position: relative;
  float: left;
  width: 100px;
  height: 100px;
  margin: 5px;
  padding: 10px;
  background: #888;
  color: #262524;
}

.element-item > * {
  margin: 0;
  padding: 0;
}

.element-item .name {
  position: absolute;

  left: 10px;
  top: 60px;
  text-transform: none;
  letter-spacing: 0;
  font-size: 12px;
  font-weight: normal;
}

.element-item .symbol {
  position: absolute;
  left: 10px;
  top: 0px;
  font-size: 42px;
  font-weight: bold;
  color: white;
}

.element-item .number {
  position: absolute;
  right: 8px;
  top: 5px;
}

.element-item .weight {
  position: absolute;
  left: 10px;
  top: 76px;
  font-size: 12px;
}

.element-item.alkali {
  background: #f00;
  background: hsl(0, 100%, 50%);
}
.element-item.alkaline-earth {
  background: #f80;
  background: hsl(36, 100%, 50%);
}
.element-item.lanthanoid {
  background: #ff0;
  background: hsl(72, 100%, 50%);
}
.element-item.actinoid {
  background: #0f0;
  background: hsl(108, 100%, 50%);
}
.element-item.transition {
  background: #0f8;
  background: hsl(144, 100%, 50%);
}
.element-item.post-transition {
  background: #0ff;
  background: hsl(180, 100%, 50%);
}
.element-item.metalloid {
  background: #08f;
  background: hsl(216, 100%, 50%);
}
.element-item.diatomic {
  background: #00f;
  background: hsl(252, 100%, 50%);
}
.element-item.halogen {
  background: #f0f;
  background: hsl(288, 100%, 50%);
}
.element-item.noble-gas {
  background: #f08;
  background: hsl(324, 100%, 50%);
}

/* Filter Gallery */
.dwk-portfolio form {
  display: flex;
  justify-content: center;
  margin: 30px 0 40px 0;
  display: none;
}

.dwk-portfolio input {
  display: none;
}

.dwk-portfolio label {
  background: #faa7b7;
  color: #fff;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}

.dwk-portfolio input:checked ~ label {
  background: #a8b4fc;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 5px 2px 15px #a8b4fc80;
}

.dwk-portfolio ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.dwk-portfolio li {
  margin: 10px 10px;
}

.dwk-portfolio figure {
  z-index: 1;
  min-width: 420px;
  overflow: hidden;
  margin: 0 5px;
  animation: show 0.8s ease;
  position: relative;
  transition: all linear 0.5s;
  border-radius: 20px;
}
.dwk-portfolio figure:hover img {
  transform: scale(1.12) rotate(4deg);
  transition: all linear 0.5s;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dwk-portfolio img {
  width: 420px;
  height: 350px;
  left: 0;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 10px 15px #a8b4fc30;
}

.dwk-portfolio figcaption {
  font-size: 24px;
  margin-top: 20px;
}

.dwk-portfolio figure figcaption {
  margin: 0;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  z-index: 999999;
  color: var(--color-white);
  font-weight: bold;
}

.dwk-portfolio figure:hover figcaption {
  opacity: 1;
  /* transform: translateY(-19px); */
}

.dwk-portfolio figcaption {
  opacity: 0;
  background: rgb(0 0 0 / 80%);
  border-radius: 0 0 20px 20px;
  padding: 30px 0 20px 0;
  transition: 0.3s;
}

.dwk-portfolio span {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: var(--color-white);
}

.dwk-portfolio .photo-source {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #969696;
  margin-bottom: 40px;
}

@keyframes transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes movement {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: translateY(20%) rotateY(10deg);
  }
}

/*============== Hire Me ==============*/
.left-title-middle {
  vertical-align: middle;
  justify-content: center;
  display: grid;
  align-content: center;
  align-items: center;
}
.hire-section .border-dark_primary {
  border-color: rgb(6 34 63 / var(--tw-border-opacity));
}
.hire-section .btn.bg-dark_primary {
  border-color: transparent;
}
.blog-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog-section ul li a {
  color: inherit;
}
.blogthumnail {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.blog-section a:hover {
  color: var(--primary-color);
  transition: all 0.5s ease;
}
.blog-section h5 {
  position: relative;
}
.blog-section h5:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 50px;
  background-color: var(--primary-color);
  height: 2px;
}
.Blogsingle-wrapper img.thumbnail {
  margin-bottom: 20px;
  border-radius: 10px;
  height: 350px;
  width: 100%;
  object-fit: cover;
}
.author-top {
  border-bottom: 1px solid var(--global--color-border);
}
.author-top p {
  margin-bottom: 0;
}
.author-top .author-thumbnail img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.author-top p,
.autho-right p {
  color: var(--color-off-black);
}
.Blogsingle-wrapper h2 {
  font-size: 1.5rem;
  margin: 20px 0 20px 0;
}
.bg-dark_primary {
  background: rgb(6 34 63);
}
.g-touch-icon-wrapper {
  border-right: 1px solid #e2e2e2;
}
.contact-info-wrapper p {
  margin-bottom: 0;
}
.g-touch-icon svg {
  width: 50px;
  height: auto;
}
.formwrapper textarea {
  resize: none;
}
.inner-header-wrapper {
  display: block;
  position: relative;
  z-index: 999;
  background-color: rgb(255 255 255 / 8%);
  box-shadow: inset 0px -2px 2px 0px rgb(255 255 255 / 35%);
}
.inner-header-wrapper .site-logo {
  width: 12%;
}
.inner-header-wrapper .site-logo img {
  filter: invert(42%) sepia(100%) saturate(100%) hue-rotate(87deg)
    brightness(200%) contrast(200%);
}
.inner-header-wrapper nav a {
  color: #fff;
}
.page-heading {
  background: url(images/menu-bg.jpg) top center no-repeat;
  background-position: center bottom;
  background-repeat: repeat;
  background-size: cover;
}
.page-heading {
  position: relative;
  background-color: #000;
}
.page-heading .page-overlay {
  /* background-color: rgba(0,0,0,0.9);
  background: linear-gradient(to right,rgb(232 232 232 / 90%),#f075752e); */
  background-color: hsla(0, 100%, 50%, 1);
  background-image: radial-gradient(
      at 40% 20%,
      hsla(23, 100%, 87%, 0.54) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, hsla(189, 77%, 80%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 50%, hsla(355, 100%, 93%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 50%, hsla(340, 100%, 86%, 0.62) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(11, 100%, 97%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(240, 100%, 92%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(201, 100%, 79%, 1) 0px, transparent 50%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;
}

.page-heading .page-titles-wrap {
  position: relative;
  padding: 60px 0;
  z-index: 2;
  width: 50%;
}

.page-heading .page-subtitle {
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: 600;
  color: #2e2e2e;
  word-wrap: break-word;
}
.page-heading .page-title {
  color: #ffffff;
}
.page-heading .page-title {
  margin: 0;
  font-size: 40px;
  color: #fff;
  line-height: 1.25;
  letter-spacing: -0.5px;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
}
.page-heading .breadcrumbs {
  border-bottom: none;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.breadcrumbs {
  background-color: rgba(255, 255, 255, 0.08);
}
.breadcrumbs {
  position: relative;
  margin-top: 0;
  z-index: 2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 0.08);
}
.breadcrumbs .breadcrumb {
  display: inline-block;
  background: none;
  margin-bottom: 0;
}
.page-heading .breadcrumb span,
.page-heading .breadcrumb span > span {
  color: #ffffff;
}
.breadcrumbs .breadcrumb span {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: color 100ms ease-in-out;
  transition: color 100ms ease-in-out;
}
.breadcrumbs .breadcrumb > span {
  padding: 18px 0;
}
.breadcrumbs .breadcrumb a::after {
  position: relative;
  margin-left: 15px;
  content: "\f105";
  font-family: "FontAwesome";
  color: rgba(255, 255, 255, 0.8);
  margin-right: 10px;
}
/* Style 2 Breadcrumb */
.style2-breadcrumb-wrapper {
  background: url(images/header-bg.png) no-repeat;
}
.style2-gradient {
  background-color: hsla(340, 0%, 100%, 1);
  background-image: radial-gradient(
      at 24% 18%,
      hsla(218, 76%, 71%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 72% 9%, hsla(223, 75%, 78%, 1) 0px, transparent 50%),
    radial-gradient(at 20% 41%, hsla(179, 79%, 62%, 1) 0px, transparent 50%),
    radial-gradient(at 64% 48%, hsla(274, 72%, 74%, 1) 0px, transparent 50%),
    radial-gradient(at 18% 36%, hsla(92, 65%, 76%, 1) 0px, transparent 50%),
    radial-gradient(at 31% 82%, hsla(48, 0%, 88%, 1) 0px, transparent 50%),
    radial-gradient(at 74% 75%, hsla(304, 82%, 70%, 1) 0px, transparent 50%);
}
.s2-breadcrumb-border {
  border-radius: 0 0 0 100px;
}
.style2-centerimage {
  position: relative;
}
.style2-centerimage img {
  position: relative;
  z-index: 999;
  width: 60%;
  height: 100%;
  margin-top: 15%;
}
.style2-breadcrumb {
  position: relative;
  top: 160px;
}
.style2-breadcrumb h3 {
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: 600;
  color: #2e2e2e;
  word-wrap: break-word;
}
/* Breadcrumb style 2 */
.breadcrumb-style2 {
  width: 100%;
  border-radius: 50px;
  padding: 0 30px;
  box-shadow: 0px 2px 0px 2px #fff;
}
.breadcrumb-style2 a,
.breadcrumb-style2 .breadcrumb ::after {
  color: rgb(6 34 63 / var(--tw-text-opacity));
}
/* About timeline */
.timeline .timeline__title {
  top: 0;
  left: 0;
  line-height: 1;
  position: relative;
  padding-left: 39px;
  padding-bottom: 30px;
  border-left: 1px solid var(--global--color-border);
  margin: 0;
}
.timeline ul {
  padding: 0 0 5px;
  list-style: none;
  margin-bottom: 0;
}
.timeline ul {
  padding: 0 0 5px;
  list-style: none;
  margin-bottom: 0;
}
.timeline ul li {
  display: block;
  position: relative;
  padding: 15px 0;
}
.timeline ul li:before {
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  content: "";
  position: absolute;
  background-color: var(--global--color-border);
}
.timeline .timeline__title {
  top: 0;
  left: 0;
  line-height: 1;
  position: relative;
  padding-left: 39px;
  padding-bottom: 30px;
  border-left: 1px solid var(--global--color-border);
  margin: 0;
}
.timeline .timeline__title:before {
  top: 0;
  left: -4px;
  width: 6px;
  height: 6px;
  display: block;
  content: "";
  z-index: 3;
  position: absolute;
  border-radius: 50%;
}
.timeline .timeline__item {
  position: relative;
  padding: 0 30px;
}
.timeline .timeline__item:before {
  top: 10px;
  left: 0;
  width: 24px;
  height: 1px;
  content: "";
  position: absolute;
  background-color: var(--global--color-border);
}
.timeline .timeline__item .timeline__item__body {
  padding-left: 9px;
}
.timeline .timeline__item .timeline__item__body .timeline__item__description {
  margin-bottom: 0;
}
.timeline .timeline__item .timeline__item__head {
  position: relative;
  padding-left: 10px;
}
.timeline .timeline__item .timeline__item__head .timeline__item__title {
  /* margin-bottom: 7px; */
}
.timeline.timeline--style-1 .timeline__item:before {
  top: 17px;
  width: 45px;
}
.timeline.timeline--style-1 .timeline__item .timeline__item__head {
  padding-left: 63px;
}
.timeline__item__icon {
  top: 0;
  left: 0;
  display: flex;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  justify-content: space-evenly;
  align-items: center;
  width: 36px;
  height: 36px;
  color: var(--color-white);
}
.timeline__item__head:before {
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  content: "";
  position: absolute;
  border-radius: 50%;
  background: var(--color-off-red);
}

@media (max-width: 992px) {
  .timeline.timeline--style-1 .timeline__item .timeline__item__subtitle {
    font-size: 10px;
    white-space: nowrap;
  }
}

.work_process_wrapper {
  position: relative;
}
.work_process_wrapper:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 50px;
  top: 12px;
  background: var(--color-off-red);
  opacity: 0.4;
  border-radius: 0 10px 0 0;
}
.work_process_wrapper:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 65%;
  left: 30%;
  top: 30px;
  border: 1px dashed var(--color-off-red);
  opacity: 0.4;
}
.work_process_wrapper.wpw_color_purple:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 60px;
  top: 12px;
  background: #ebbcff;
  opacity: 1;
  border-radius: 7px 12px 12px 0;
}
.work_process_wrapper.wpw_color_purple:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 65%;
  left: 30%;
  top: 30px;
  border: 1px dashed #ebbcff;
  opacity: 0.4;
}
.work_process_wrapper.wpw_color_gray:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 50px;
  top: 12px;
  background: #5c5c5c;
  opacity: 0.4;
  border-radius: 10px 0 0 0;
}
.work_process_wrapper.wpw_color_gray:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 65%;
  left: 30%;
  top: 30px;
  border: 1px dashed #5c5c5c;
  opacity: 0.4;
}
.work_process_wrapper.wpw_color_blue:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 60px;
  top: 12px;
  background: #3d0fe2;
  opacity: 0.4;
  border-radius: 6px 12px 0 0;
}
.work_process_wrapper.wpw_color_blue:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 65%;
  left: 30%;
  top: 30px;
  border: 1px dashed #3d0fe2;
  opacity: 0.4;
}
.work_process_wrapper.wpw_color_yellow:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 60px;
  top: 12px;
  background: #f9e2a0;
  opacity: 1;
  border-radius: 6px 12px 0 0;
}
.work_process_wrapper.wpw_color_yellow:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 65%;
  left: 30%;
  top: 30px;
  border: 1px dashed #f9e2a0;
  opacity: 0.4;
}
.work_process_wrapper #lottie {
  float: left;
}
.section-faq {
  background: url(images/faqbg.jpeg) no-repeat;
  background-position: center !important;
  background-size: cover !important;
  background-attachment: fixed;
}
.section-faq .MuiPaper-elevation1.accordion_item {
  background: rgb(245 250 255);
  margin-bottom: 30px;
  border-radius: 25px;
  box-shadow: 0px 5px 10px 0px rgb(155 155 155 / 10%) !important;
}
.section-faq .MuiPaper-elevation1.accordion_item:before {
  display: none;
}
.section-faq .cMuiAccordionSummary-root {
  padding: 0 25px;
}
.section-faq .MuiAccordionSummary-content {
  display: block;
  padding-right: 50px;
}
.section-faq .MuiAccordionSummary-content .MuiTypography-root {
  color: #2274a5;
  font-weight: bold;
  font-family: inherit;
}
.section-faq .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 2px 14.7px 0.3px rgba(149, 152, 200, 0.1);
  transition: all 0.5s ease;
  font-size: 24px;
  font-weight: 300;
  /* width: 100%; */
  display: flex;
  /* text-align: center; */
  align-content: center;
  justify-content: center;
  line-height: 50px;
  flex-direction: row;
  align-items: center;
}
/* img.faqimage {
  position: absolute;
  bottom: 0;
} */
.services-grid .img-wrap {
  float: left;
  width: 100%;
  height: 320px;
  overflow: hidden;
}
.services-grid .img-wrap a {
  float: left;
  width: 100%;
  overflow: hidden;
}
.services-grid .img-wrap img {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services-grid .img-wrap img:hover {
  opacity: 0.7;
  -webkit-transform: scale(1.12) rotate(4deg);
  -moz-transform: scale(1.12) rotate(4deg);
  -ms-transform: scale(1.12) rotate(4deg);
  -o-transform: scale(1.12) rotate(4deg);
  transform: scale(1.12) rotate(4deg);
}
.services-grid .services-wrap {
  background-color: var(--color-white);
  min-height: 320px;
  width: 100%;
  float: left;
}
.services-grid .services-caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 320px;
  text-align: left;
  margin: 0;
  padding: 0 18px 0px 20px;
  background: var(--color-white);
  background-repeat: no-repeat;
  background-position: right top;
  -webkit-box-shadow: 0 15px 46px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 15px 46px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 15px 46px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 15px 46px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 46px rgba(0, 0, 0, 0.1);
}
.services-grid .services-caption h3 {
  color: #505050;
  padding-bottom: 15px;
  font-size: 22px;
  font-weight: 700;
  margin-top: 0px;
}
.services-grid .services-caption p {
  margin-bottom: 15px;
  font-size: 15px;
  color: #74787a;
  font-weight: 400;
  line-height: 24px;
}
.services-grid .services-caption h3 a {
  color: #505050;
  text-decoration: none;
  font-size: 17px;
  font-weight: 700;
}
.services-grid .services-caption h3:hover a {
  color: #43006a;
}
.services-grid .services-box {
  margin-bottom: 30px;
}
.services-caption .main-btn {
  position: absolute;
  bottom: 35px;
}
.service--style-1 {
}
.service--style-1:hover img {
  -webkit-transform: scale(1.1) translateY(-5px);
  -ms-transform: scale(1.1) translateY(-5px);
  transform: scale(1.1) translateY(-5px);
}
.services-caption img {
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
  width: 50px;
  margin-bottom: 20px;
}

.services-caption a {
  position: relative;
  margin-top: 12px;
  font-weight: bold;
}
.services-caption a:before {
  content: "";
  position: absolute;
  width: 0;
  bottom: 0;
  height: 1px;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
}
.services-caption a:hover:before {
  content: "";
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--primary-color);
}
/* Sidebar */
.sidebar-wrapper ul li {
  border-bottom: 1px solid var(--global--color-border);
  display: block;
  padding: 15px 0;
  overflow: hidden;
  display: flex;
  gap: 10px;
}
.thumbnail-wrapper {
  float: left;
  width: 25%;
}
.sidebar-wrapper ul li img {
  border-radius: 5px;
}
.sidebar-content {
  width: 75%;
  float: left;
}
.sidebar-content .date {
  color: var(--color-off-black);
}
.social-icons li a {
  color: var(--color-light-blue);
}
.ul-tags li {
  background-color: var(--color-light-blue);
  padding: 10px 25px;
  border-radius: 30px;
}
.footer-social-section {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}
.footer-social-section.hide {
  transform: translateY(100%);
}
.footer-social-section.show {
  transform: translateY(0);
}
.footer-social-section ul {
  gap: 30px;
}
.footer-social-section ul li {
  box-shadow: var(--card-shadow);
  text-align: left;
  background: var(--color-white);
  padding: 1.25rem;
}
.footer-social-section ul li h6 {
  font-size: 14px;
  line-height: 18px;
}
.footer-social-section ul li p {
  margin-bottom: 0;
}
.footer-social-section ul li a {
  gap: 15px;
  align-items: center;
}
.footer-social-section ul li img {
  width: 36px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h1.rotate-90,
  .style2-centerimage img {
    display: none;
  }
  .dwk-header-top {
    width: 100%;
    padding: 1rem;
  }
  .logo-wrapper {
    float: left;
    width: 80%;
  }
  .navbar-wrapper {
    float: right;
  }
  .container.py-20 {
    padding: 3rem 0;
  }
  .container .pt-20 {
    padding-top: 3rem;
  }
  .container .pb-20 {
    padding-bottom: 3rem;
  }
  header .bg-primaryLinear {
    background-color: var(--color-white);
    background-image: none;
  }
  header h2 {
    font-size: 1.5rem;
  }
  header button {
    background: rgb(6 34 63 / var(--tw-text-opacity)) !important;
  }
  .mobilemenu {
    position: absolute;
    top: 60px;
    width: 100%;
    left: 0;
    padding: 1rem;
  }
  .mobilemenu > ul {
    margin-top: 0;
    background: none;
    background: rgba(182, 204, 245, 1);
    padding: 1rem;
    gap: 15px;
  }
  .main-content {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .services-grid .services-wrap {
    float: none;
  }
  .testimonial-section > .container > .pt-20 {
    padding-bottom: 5rem;
  }
  .testimonial-section .testimonial-image {
    display: none;
  }
  .testimonial-wrapper {
    padding: 35px 50px;
  }
  .testimonialmainwrapper {
    max-width: 100%;
    margin-top: 2.5rem;
  }
  .flex.items-center.justify-center.-m-6.overflow-hidden.bg-white.rounded-full {
    float: left;
    width: 40%;
  }
  .service--style-1 {
    display: flex;
    flex-flow: column-reverse;
  }
  .work_process_container {
    flex-direction: column;
  }
  .progress-content {
    float: left;
    width: 76%;
    text-wrap: unset;
    display: grid;
  }
  .contact-info-wrapper > .bg-white {
    display: flex;
  }
  .g-touch-icon-wrapper {
    padding-right: 1rem;
  }
  .skills__items.my-20 {
    margin-bottom: 0;
  }
  .skills-section .skills-right {
    margin-bottom: 0;
  }
  .footer-social-section ul {
    gap: 10px;
  }
  .footer-social-section ul li {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-social-section ul li .si-right {
    display: none;
  }
}
